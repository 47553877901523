
<!-- @if (!showSidebar){ -->
<div *ngIf="!showSidebar">

  <app-navbar class="fixed-top" *ngIf="!hideNavbar"></app-navbar>

  <div id="navbarId" class="centralize">
    <router-outlet></router-outlet>

    <div></div>
  </div>

  <div class="text-black p-3 mt-auto position-relative" style="border-top: 5px solid #FFCC00;" *ngIf="!hideFooter">
    <app-footer style=" height: 70%;"></app-footer>
  </div>

</div>

<!-- }
@else { -->
<app-sidenav *ngIf="showSidebar"></app-sidenav>
<!-- } -->

