import { Component, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { ConhecaProgramaComponent } from './core/components/programa/conheca-programa/conheca-programa.component';
import { NavbarComponent } from './shared/navigation/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { LogBadgeComponent } from './shared/log-badge/log-badge.component';
import { SidenavComponent } from './shared/sidenav/sidenav.component';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, NavbarComponent, FooterComponent,LogBadgeComponent, SidenavComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'nota-premiada-acreana-frontend';
  hideNavbar: boolean;
  hideFooter: boolean
  showSidebar: boolean = false;

  constructor (private renderer: Renderer2, private router: Router) {

  }

  ngOnInit(): void {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showSidebar = this.router.url.includes('backoffice');
      }
    });
    
  this.router.events.subscribe((event) => {
    if (event instanceof NavigationEnd) {
      this.hideNavbar = this.router.url === '/login'
      this.hideFooter = this.router.url === '/login'
      this.toggleNavbar()
    }
  });
}

toggleNavbar(): void {
  
  let div = document.getElementById('navbarId');

  if(this.hideNavbar && div) {
    div.classList.add('page-loaded');
  }else{
    div?.classList.remove('page-loaded');
  }
}
  
}
