import { ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MarkdownModule } from 'ngx-markdown';
import { provideToastr } from 'ngx-toastr';
import { routes } from './app.routes';
import { loggerInterceptor } from './core/interceptors/token-interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      ),
provideHttpClient(
  withInterceptors([loggerInterceptor]),
),
    importProvidersFrom(MarkdownModule.forRoot()),
    provideToastr({
      positionClass: 'toast-top-right',
      maxOpened: 1,
      preventDuplicates: true
    }),
    provideAnimations(),
  ],
}