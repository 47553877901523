import { CanActivateFn, Router } from '@angular/router';
import { SessionService } from './services/shared/session.service';
import { inject } from '@angular/core';
import { jwtDecode } from 'jwt-decode';


export const userGuard: CanActivateFn = (route, state)  => {
  let count = 0
  const authService: SessionService = inject(SessionService);
  const router: Router = inject(Router)

  const token = localStorage.getItem('AccessToken');
   
  if (token) {
    
    const decodedToken = jwtDecode(token);

    const iToken: any = decodedToken;
     
    if (iToken.Tag === "Cidadão" || iToken.Tag === "Funcionario SEFAZ") {
       
      return true
    } else {
       
      router.navigate(['/']); 
      return false
    }
  }
  else {
    router.navigate(['/']); 
    return false
  }
};