import { Routes } from '@angular/router';
import { generalGuard } from './general.guard';
import { userGuard } from './user.guard';
import { institutionGuard } from './institution.guard';


export const routes: Routes = [

    { path: '', redirectTo: 'home', pathMatch: 'full' },
    {
        path: 'recuperar-senha',
        loadComponent: () =>
            import('./core/components/pessoa/recuperar-senha/recuperar-senha.component')
                .then(m => m.RecuperarSenhaComponent),
    },
    {
        path: 'aprovacao-usuario',
        loadComponent: () =>
            import('./core/components/ajuda/aprovar-registro/aprovar-registro.component')
                .then(m => m.AprovarRegistroComponent)
    },
    {
        path: 'programa',
        children: [
            {
                path: 'conheca-programa',
                loadComponent: () =>
                    import('./core/components/programa/conheca-programa/conheca-programa.component')
                        .then(m => m.ConhecaProgramaComponent),

            },
            {
                path: 'educacao-fiscal',
                loadComponent: () =>
                    import('./core/components/programa/educacao-fiscal/educacao-fiscal.component')
                        .then(m => m.EducacaoFiscalComponent)
            },
            {
                path: 'educacao-fiscal-strapi',
                loadComponent: () =>
                    import('./core/components/strapi/educacao-fiscal-strapi/educacao-fiscal-strapi.component')
                        .then(m => m.EducacaoFiscalStrapiComponent)
            },
            {
                path: 'legislacao',
                loadComponent: () =>
                    import('./core/components/programa/legislacao/legislacao.component')
                        .then(m => m.LegislacaoComponent)
            },
        ]
    },
    {
        path: 'backoffice',
        children: [
            {
                path: 'homologar',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/entidades-sociais/homologar/homologar.component')
                        .then(m => m.HomologarComponent)

            },
            {
                path: 'home',
                canActivate: [generalGuard],
                loadComponent: () => import('./core/components/backoffice/home-backoffice/home-backoffice.component').then(m => m.HomeBackofficeComponent),
            },
            {
                path: 'enviar-email',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/parametros-gerais/email/email/email.component').then(m => m.EmailComponent)

            },
            {
                path: 'parametros/tipo-email',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/parametros-gerais/email/parametros-email/parametros-email.component').then(m => m.ParametrosEmailComponent)

            },
            {
                path: 'listar-email',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/parametros-gerais/email/listar-emails/listar-emails.component').then(m => m.ListarEmailsComponent)

            },
            {
                path: 'listar-sorteios',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/parametros-gerais/sorteios/listar-sorteios/listar-sorteios.component').then(m => m.ListarSorteiosComponent)

            },
            {
                path: 'listar-solicitacoes',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/entidades-sociais/listar-solicitacao/listar-solicitacao.component')
                        .then(m => m.ListarSolicitacaoComponent)
            },
            {
                path: 'listar-rateios',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/parametros-gerais/rateio/listar-rateio/listar-rateio.component')
                        .then(m => m.ListarRateioComponent)
            },
            // {
            //     path: 'gerenciar-permissoes',
            //     canActivate: [generalGuard],
            //     loadComponent: () =>
            //         import('./core/components/backoffice/permissoes/permissoes.component')
            //             .then(m => m.PermissoesComponent)
            // },
            {
                path: 'gerenciar-permissoes',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/permissoes/permissoes.component')
                        .then(m => m.PermissoesComponent)
            },
            {
                path: 'listar-premios',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/parametros-gerais/premios/listar-premios/listar-premios/listar-premios.component')
                        .then(m => m.ListarPremiosComponent)
            },
            {

                path: 'parametros/email',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/parametros-gerais/email/parametros-email/parametros-email.component').then(m => m.ParametrosEmailComponent)

            },
            {
                path: 'parametros/premios',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/parametros-gerais/premios/parametros-premio/premios.component').then(m => m.PremiosComponent)

            },
            {
                path: 'parametros/sorteios',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/parametros-gerais/parametros-gerais/parametros-gerais.component').then(m => m.ParametrosGeraisComponent)

            },
            {
                path: 'rotinas/atualizar-ganhadores',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/rotinas/atualizar-ganhadores/atualizar-ganhadores.component').then(m => m.AtualizarGanhadoresComponent)

            },
            {
                path: 'parametros/rateio',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/parametros-gerais/rateio/parametros-rateio/rateio.component').then(m => m.RateioComponent)

            },
            {
                path: 'manutencao/sorteios',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/manutencao/manutencao-sorteios/manutencao-sorteios.component').then(m => m.ManutencaoSorteiosComponent)

            },
            {
                path: 'listar-manutencao-sorteios',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/manutencao/listar-manutencao-sorteio/listar-manutencao-sorteio.component').then(m => m.ListarManutencaoSorteioComponent)

            },
            {
                path: 'rotinas',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/rotinas/rotinas.component').then(m => m.RotinasComponent)
            },
            {
                path: 'comunicacao/premios-es',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/parametros-gerais/premios/listar-premios/listar-premios/listar-premios.component').then(m => m.ListarPremiosComponent)
            },
            {
                path: 'comunicacao/premios-pf',
                canActivate: [generalGuard],
                loadComponent: () =>
                    import('./core/components/backoffice/parametros-gerais/premios/listar-premios-pf/listar-premios-pf.component').then(m => m.ListarPremiosPfComponent)
            },
            
        ]
    },
    {
        path: 'entidade-social',
        children: [

            {
                path: 'registrar-senha',
                loadComponent: () =>
                    import('./core/components/entidades-sociais/recuperar-registro/recuperar-registro.component')
                        .then(m => m.RecuperarRegistroComponent)

            },

            {
                path: 'home-entidade',
                canActivate: [institutionGuard],
                loadComponent: () =>
                    import('./core/components/entidades-sociais/home-entidade/home-entidade.component')
                        .then(m => m.HomeEntidadeComponent)

            },

            {
                path: 'documentacao',
                loadComponent: () =>
                    import('./core/components/entidades-sociais/documentacao/documentacao.component')
                        .then(m => m.DocumentacaoComponent)
            },
            {
                path: 'entidades-cadastradas',

                loadComponent: () =>
                    import('./core/components/entidades-sociais/entidades-cadastradas/entidades-cadastradas.component')
                        .then(m => m.EntidadesCadastradasComponent)
            },
            {
                path: 'cadastro',
                loadComponent: () =>
                    import('./core/components/entidades-sociais/cadastro/cadastro.component')
                        .then(m => m.CadastroComponent)
            },
            {
                path: 'atualizar-usuario',
                loadComponent: () =>
                    import('./core/components/pessoa/atualizar-user/atualizar-user.component')
                        .then(m => m.AtualizarUserComponent)
            },
            {
                path: 'descadastro',
                canActivate: [institutionGuard],
                loadComponent: () =>
                    import('./core/components/entidades-sociais/descadastro/descadastro.component')
                        .then(m => m.DescadastroComponent)
            },
            {
                path: 'acompanhar-solicitacao',
                loadComponent: () =>
                    import('./core/components/entidades-sociais/acompanhe-solicitacao/acompanhe-solicitacao.component')
                        .then(m => m.AcompanheSolicitacaoComponent)
            },
            {
                path: 'solicitar-alteracao',
                canActivate: [institutionGuard],
                loadComponent: () =>
                    import('./core/components/entidades-sociais/solicitar-alteracao/solicitar-alteracao.component')
                        .then(m => m.SolicitarAlteracaoComponent)
            },
            {
                path: 'listar-entidades',
                loadComponent: () =>
                    import('./core/components/entidades-sociais/entidades-cadastradas/entidades-cadastradas.component')
                        .then(m => m.EntidadesCadastradasComponent)
            },
            {
                path: 'meus-premios-es',
                canActivate: [institutionGuard],
                loadComponent: () =>
                    import('./core/components/entidades-sociais/meus-premios-es/meus-premios-es.component')
                        .then(m => m.MeusPremiosEsComponent)
            },

        ]
    },
    {
        path: 'pessoa',
        children:
            [
                {
                    path: 'cadastro',
                    loadComponent: () =>
                        import('./core/components/pessoa/cadastro-pessoa-fisica/cadastro-pessoa-fisica.component')
                            .then(m => m.CadastroPessoaFisicaComponent)
                },
                {
                    path: 'excluir',
                    canActivate: [userGuard],
                    loadComponent: () =>
                        import('./core/components/pessoa/deletar-usuario/deletar-usuario.component')
                            .then(m => m.DeletarUsuarioComponent)
                },
                {
                    path: 'eqweqwe',
                    canActivate: [userGuard],
                    loadComponent: () =>
                        import('./core/components/pessoa/meus-pontos/meus-pontos.component')
                            .then(m => m.MeusPontosComponent)
                },
                {
                    path: 'meus-pontos',
                    canActivate: [userGuard],
                    loadComponent: () =>
                        import('./core/components/pessoa/meus-pontos/meus-pontos.component')
                            .then(m => m.MeusPontosComponent)
                },
                {
                    path: 'trocar-entidade',
                    canActivate: [userGuard],
                    loadComponent: () =>
                        import('./core/components/pessoa/entidade-favorita/entidade-favorita.component')
                            .then(m => m.EntidadeFavoritaComponent)
                },
                {
                    path: 'atualizar',
                    canActivate: [userGuard],
                    loadComponent: () =>
                        import('./core/components/pessoa/atualizar-user/atualizar-user.component')
                            .then(m => m.AtualizarUserComponent)
                },
                {
                    path: 'premios',
                    canActivate: [userGuard],
                    loadComponent: () =>
                        import('./core/components/pessoa/meus-premios/meus-premios.component')
                            .then(m => m.MeusPremiosComponent)
                },
                {
                    path: 'minhas-notas',
                    canActivate: [userGuard],
                    loadComponent: () =>
                        import('./core/components/pessoa/minhas-notas/minhas-notas.component')
                            .then(m => m.MinhasNotasComponent)
                },
                {
                  path: 'minhas-mensagens',
                  canActivate: [userGuard],
                  loadComponent: () =>
                      import('./core/components/pessoa/minhas-mensagens/minhas-mensagens.component')
                          .then(m => m.MinhasMensagensComponent)
              }
            ]
    },
    {
        path: 'login',
        children:
            [
                {
                    path: '',
                    loadComponent: () =>
                        import('./core/components/login/tela-login/tela-login.component')
                            .then(m => m.TelaLoginComponent)
                },
            ]
    },
    {
        path: 'sorteio',
        children:
            [
                {
                    path: 'sorteios-realizados',
                    loadComponent: () =>
                        import('./core/components/sorteio/sorteios-realizados/sorteios-realizados.component')
                            .then(m => m.SorteiosRealizadosComponent)
                },
                {
                    path: 'como-funciona',
                    loadComponent: () =>
                        import('./core/components/strapi/como-funciona/como-funciona.component')
                            .then(m => m.ComoFuncionaComponent)
                },
                {
                    path: 'como-receber',
                    loadComponent: () =>
                        import('./core/components/strapi/como-receber/como-receber.component')
                            .then(m => m.ComoReceberComponent)
                },
                {
                    path: 'premiacao-instituicao',
                    loadComponent: () =>
                        import('./core/components/sorteio/premiacao-instituicao/premiacao-instituicao.component')
                            .then(m => m.PremiacaoInstituicaoComponent)
                },
                    {
                    path: 'instituicoes-ganhadoras',
                    loadComponent: () =>
                        import('./core/components/sorteio/premiacao-instituicao/premiacao-instituicao.component')
                            .then(m => m.PremiacaoInstituicaoComponent)
                }
            ]
    },
    {
        path: 'home',
        loadComponent: () =>
            import('./core/components/home/homepage/homepage.component').then(m => m.HomepageComponent)
    },
    {
        path: 'ajuda',
        children:
            [
                {
                    path: 'gestao-programa',
                    loadComponent: () =>
                        import('./core/components/strapi/gestao-programa/gestao-programa.component')
                            .then(m => m.GestaoProgramaComponent)
                },
                {
                    path: 'reativar-cadastro',
                    loadComponent: () =>
                        import('./core/components/ajuda/reativacao-user/reativacao-user.component')
                            .then(m => m.ReativacaoUserComponent)
                },
                {
                    path: 'reenviar-email',
                    loadComponent: () =>
                        import('./core/components/ajuda/reenviar-email/reenviar-email.component')
                            .then(m => m.ReenviarEmailComponent)
                },
                {
                    path: 'fale-conosco',
                    loadComponent: () =>
                        import('./core/components/ajuda/fale-conosco/fale-conosco.component')
                            .then(m => m.FaleConoscoComponent)
                },
                {
                    path: 'material-divulgacao',
                    loadComponent: () =>
                        import('./core/components/ajuda/material-divulgacao/material-divulgacao.component')
                            .then(m => m.MaterialDivulgacaoComponent)
                },
            ]
    },
    {
        path: 'noticia',
        loadComponent: () =>
            import('./core/components/noticias/noticia-comum/noticia/noticia.component')
                .then(m => m.NoticiaComponent)
    },
    {
        path: 'painel-noticias',
        loadComponent: () =>
            import('./core/components/noticias/painel-noticias/painel-noticias/painel-noticias.component')
                .then(m => m.PainelNoticiasComponent)
    },
    {
        path: 'home-usuario',
        canActivate: [userGuard],
        loadComponent: () =>
            import('./core/components/pessoa/home-pessoa-fisica/home-pessoa-fisica/home-pessoa-fisica.component').then(m => m.HomePessoaFisicaComponent)
    },

];
